// 
// _nav.scss
// 
//Nav
.nav-bg {
    background: $navs-bg !important;
}
.nav-pills {
    .nav-link{
        color: $dark !important;
        &.nav-link-alt {
            color: rgba($white, 0.5) !important;
            &:hover {
                background: none !important;
            }
            &.active {
                background: $nav-link-alt !important;
            }
        }
        &.active {
            color: $white !important;
        }
    }
}

.nav-tabs {
    .nav-item.show .nav-link,
    .nav-link.active {
        color: $primary !important;
        border-color: transparent transparent $primary !important;
    }
    .nav-link:focus,
    .nav-link:hover {
        border-color: transparent;
    }
}