
//
// accordion.scss
//
//Accordion
.accordion {
    .accordion-item {
        overflow: hidden;
        background: transparent;
        .accordion-button {
            font-weight: 600;
            text-align: left;
            box-shadow: none;
            padding: 1rem 2.5rem 1rem 1.25rem;
            &:focus {
                box-shadow: none;
            }
            &:before {
                content: '\F0143';
                display: block;
                font-family: 'Material Design Icons';
                font-size: 16px;
                color: $primary;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.5s ease;
            }
            &.collapsed {
                background-color: $white;
                color: $dark !important;
                transition: all 0.5s;
                &:before {
                    top: 15px;
                    transform: rotate(180deg);
                    color: $dark !important;
                }
            }
            &:after {
                display: none;
            }
        }
    }
}